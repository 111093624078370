import "./App.css";
import { useState } from "react";

function App() {
  const [count, setCount] = useState(0);
  return (
    <div className="App">
      <h1> {count}</h1>
      <button
        onClick={() => setCount(count + 1)}
        style={{
          padding: "10px 30px",
          marginRight: 3,
          backgroundColor: "pink",
        }}
      >
        +
      </button>
      <button
        onClick={() => setCount(count - 1)}
        style={{
          padding: "10px 30px",
          marginLeft: 3,
          backgroundColor: "yellow",
        }}
      >
        -
      </button>
    </div>
  );
}

export default App;
